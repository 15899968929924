import { menu } from './menu';
import { swup } from './swup';

const rdy = (fn: () => void) => {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
};

// on dom content loaded
rdy(() => {
  console.log(process.env.NODE_ENV);
  swup();
  menu();
});
