import Swup from 'swup';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';

declare global {
  var swup: Swup;
}

const NOT_SELECTORS = `:not([href*="/wp-admin"])` + `:not([href*=".pdf"])` + `:not([target="_blank"])`;

export const swup = () => {
  const instance = new Swup({
    containers: [
      '#menu ul',
      'main',
      // if admin bar is present, add it to the containers
      document.body.classList.contains('admin-bar') ? '#wpadminbar' : '',
    ].filter(Boolean),
    animationSelector: false,
    linkSelector: `a[href^="${window.location.origin}"]${NOT_SELECTORS}, ` + `a[href^="/"]${NOT_SELECTORS}`,
    plugins: [
      ...(process.env.NODE_ENV !== 'development' ? [new SwupHeadPlugin()] : []),
      new SwupBodyClassPlugin(),
      new SwupScriptsPlugin(),
      new SwupPreloadPlugin(),
    ],
    // skipPopStateHandling: () => false,
  });

  if (!window.swup) {
    window.swup = instance;
  }

  return instance;
};
