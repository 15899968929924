export function menu() {
  const menu = document.querySelector('#menu') as HTMLDivElement;
  const openButton = document.querySelector('.hamburger.open');
  const closeButton = document.querySelector('.hamburger.close');
  if (menu) {
    const openMenu = () => {
      menu.classList.add('is-open');
      // animate translateX
      const animation = menu.animate([{ transform: 'translateX(100%)' }, { transform: 'translateX(0)' }], {
        duration: 300,
        easing: 'ease-in-out',
      });
      animation.onfinish = () => {
        menu.style.transform = 'translateX(0)';
      };
    };

    const closeMenu = () => {
      // animate translateX
      const animation = menu.animate([{ transform: 'translateX(0)' }, { transform: 'translateX(100%)' }], {
        duration: 300,
        easing: 'ease-in-out',
      });
      animation.onfinish = () => {
        menu.style.transform = '';
        menu.classList.remove('is-open');
      };
    };

    openButton?.addEventListener('click', openMenu);

    closeButton?.addEventListener('click', closeMenu);

    window.swup.hooks.on('content:replace', () => {
      closeMenu();
    });
  }
}
